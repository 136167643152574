import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import {graphql, navigate} from 'gatsby'
import PageHeaderFull from '../../components/PageHeaderFull/PageHeaderFull'
import homeStyle from './homeStyle'
import Footer from '../../components/Footer/Footer'
import FerienhaeuserLinksSection from '../../elements/FerienhaeuserLinksSection/FerienhaeuserLinksSection'
import RecentBlogPosts from '../../elements/RecentBlogPosts/RecentBlogPosts'
import InstagramSection from '../../components/InstagramSection/InstagramSection'
import BannerWithImg from '../../elements/BannerWithImg/BannerWithImg'
import SpainHolidaysCta from '../../elements/SpainholidaysCta/SpainHolidaysCta'
import HeaderProvider from '../../components/Header/HeaderProvider'
import TriggerSearchBar from "../WetsuitFinder/TriggerSearchBar";
import SEO from '../../components/SEO'

// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'

// Sections for this page


const Home = props => {
    const {classes, data} = props


    const trigger = async () => {
        TriggerSearchBar()
        navigate(props.pageContext.locale === "de" ? "/de/wetsuit-finder": "/wetsuit-finder")

    }

    return (
        <div className={classes.backgroundColor}>
          <SEO
            seoMetaTags={data.datoCmsHome.seoMetaTags.tags}
            home
            actLang={props.pageContext.locale}
            localURLS={[
              {'locale': 'de', 'value': ''},
              {'locale': 'en', 'value': ''},
            ]}
          />
            <HeaderProvider
                localURLS={[
                    {'locale': 'de', 'value': ''},
                    {'locale': 'en', 'value': ''},
                ]}
                actLang={props.pageContext.locale}
                SEOtitle={'Perfect Wetsuit'}
                SEOdescription={data.datoCmsHome.pageSubtitle}
                SEOpathname={props.pageContext.locale === "de" ? "/de/": "/"}
                absolute
                logoAsH1
                light
            />

            <PageHeaderFull
                image={data.datoCmsHome.headerImage.fluid}
                alt={data.datoCmsHome.headerImage.alt}
                title={data.datoCmsHome.title}
                subtitle={data.datoCmsHome.pageSubtitle}
                transl={data.datoCmsTranslationMain}
                actLang={props.pageContext.locale}
                onSBClick={() => trigger()}
            />

            <FerienhaeuserLinksSection
                actLang={props.pageContext.locale}
                seitenlinks={data.datoCmsHome.threeLinks}
            />
            <RecentBlogPosts
                articles={data.allDatoCmsArticle.nodes}
                actLang={props.pageContext.locale}
                whitebg
            />
            <BannerWithImg
                title={data.datoCmsHome.bannerWithBackgroundImage[0].title}
                text={data.datoCmsHome.bannerWithBackgroundImage[0].text}
                image={data.datoCmsHome.bannerWithBackgroundImage[0].image}
                buttonTitle={data.datoCmsHome.bannerWithBackgroundImage[0].buttonTitle}
                link={data.datoCmsHome.bannerWithBackgroundImage[0].buttonLink.url}
                actLang={props.pageContext.locale}
            />
            <InstagramSection/>
            <SpainHolidaysCta
                title={data.datoCmsHome.bannerBlank[0].title}
                subtitle={data.datoCmsHome.bannerBlank[0].subtitle}
                buttonTitle={data.datoCmsHome.bannerBlank[0].buttonTitle}
                buttonLink={data.datoCmsHome.bannerBlank[0].buttonLink}
            />
            <Footer
                actLang={props.pageContext.locale}
            />
        </div>
    )

}

export default withStyles(homeStyle)(Home)


export const query = graphql`
    query($locale: String!){
        datoCmsHome(locale:{eq:$locale}) {
            seoMetaTags{
                tags
            }
            title
            pageSubtitle
            headerImage{
                alt
                fluid(maxWidth:1800){
                    ...GatsbyDatoCmsFluid_noBase64
                }
            }
            threeLinks{
                title
                subtitle
                image{
                    alt
                    fluid(maxWidth:1800){
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
                link{
                    ...on DatoCmsSizeFinder{
                        url
                    }
                    ...on DatoCmsWetsuitFinder{
                        url
                    }
                    ...on DatoCmsGuide{
                        url
                    }
                    ...on DatoCmsSizeChart{
                        url
                    }

                }
            }
            bannerWithBackgroundImage{
                title
                text
                image{
                    alt
                    fluid(maxWidth:1800){
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
                buttonTitle
                buttonLink{
                    ...on DatoCmsSizeFinder{
                        url
                    }
                    ...on DatoCmsWetsuitFinder{
                        url
                    }

                }
            }
            bannerBlank{
                title
                subtitle
                buttonLink
                buttonTitle
            }
        }
        allDatoCmsArticle(
            limit:6

            sort:{
                fields:meta___updatedAt
                order:DESC
            }
            filter:{
                meta:{status:{eq:"published"}  }
                locale:{eq:$locale}
            }
        ){
            nodes{
                locale
                url
                title
                meta{
                    updatedAt(formatString: "MM-DD-YYYY")
                }
                summary
                featuredImage{
                    alt
                    fluid(maxWidth:705){
                        src
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
            }
        }
        datoCmsTranslationMain (locale:{eq:$locale}){
            metricDropDownText
            mobileSearchMenubuttonLabel
            searchButtonTitle
            searchSectionBrandsTitle
            searchSectionDimensionsChest
            searchSectionDimensionsHeight
            searchSectionDimensionsTitle
            searchSectionDimensionsHips
            searchSectionDimensionsWaist
            searchSectionDimensionsWeight
            searchSectionGenderKids
            sortProductsItemNewproducts
            sortProductsItemLowestprice
            sortProductsItemHeighestprice
            sortProductsDropdownTitle
            showMoreButtonLabel
            searchSectionZiptypeTitle
            searchSectionZiptypeNozip
            searchSectionZiptypeFrontzip
            searchSectionZiptypeBackzip
            searchSectionTemperatureWinter
            searchSectionTemperatureTropics
            searchSectionTemperatureTitle
            searchSectionTemperatureSummer
            searchSectionTemperatureAutumn
            searchSectionTemperatureAll
            searchSectionPriceTitle
            searchSectionGenderWomen
            searchSectionGenderTitle
            searchSectionGenderMen
            countryDropdownText
        }
    }
`