import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import Img from 'gatsby-image'

import { Link } from 'gatsby'

import ReactRevealFadeIn from '../../components/ReactRevealFadeIn/ReactRevealFadeIn'
import Section from '../../components/Section/Section'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import ferienhaeuserLinksSectionStyle from './ferienhaeuserLinksSectionStyle'

const FerienhaeuserLinksSection = (props) => {
  const { classes, seitenlinks,actLang } = props

  return (
    <Section backgroundColor={'#f2f2ee'} paddingBottom={40} paddingTop={160}>

      <SectionHeader header={'Perfect Wetsuit'}/>


      <div className={classes.wrapper}>

        {seitenlinks.map(element => {
          return (
            <ReactRevealFadeIn>
              <div className={classes.itemWrapper}>

                <Link to={actLang ==="de"? '/de/' + element.link.url : '/' + element.link.url }>
                  <div className={classes.imgWrapper}>
                    <Img
                      className={classNames(classes.imgClass, classes.imageDarkenOnHover)}
                      alt={element.image.alt}
                      fluid={element.image.fluid}
                      backgroundColor={'#f2f2f2'}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>
                </Link>
                <Link to={actLang ==="de"? '/de/' + element.link.url : '/' + element.link.url }>
                  <div className={classes.itemGrid}>
                    <h3 className={classes.H3}>
                      {element.title}
                    </h3>

                    <p className={classes.P}>
                      {element.subtitle}
                    </p>

                  </div>
                </Link>
              </div>
            </ReactRevealFadeIn>

          )
        })}


      </div>


    </Section>
  )


}


export default withStyles(ferienhaeuserLinksSectionStyle)(FerienhaeuserLinksSection)
